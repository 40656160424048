html {
    font-size: 10px;
    image-rendering: -webkit-optimize-contrast;
}

html,
body {
    padding: 0;
    margin: 0;
}

html,
body,
#root,
.App,
.fullHeight {
    height: 100%;
}

*,
::before,
::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-family: "Open Sans", sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1;
}

h1 {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0 auto 2rem;
    font-size: 2.2rem;
    line-height: 3.2rem;
    color: #000000;
    text-align: left;
    font-weight: 400;
    text-transform: uppercase;
    border-bottom: #d3d3d3;
}

a {
    text-decoration: none;
    cursor: pointer;
}

a,
a:hover {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.main {
    max-width: 90%;
    padding: 0 10px;
    margin: 0 auto;
    min-height: calc(100vh - 8.5rem - 5.8rem - 2rem);
}
