.header {
  height: 8.5rem;
  min-width: 30rem;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;

  .wrap {
    position: relative;
    height: 100%;
    max-width: 90%;
    margin: 0 auto;
    background-color: #f8f8f8;

    .logo {
      width: 13rem;
      position: absolute;
      top: 50%;
      left: 2rem;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);

      img {
        display: block;
        width: 100%;
        margin: 0;
      }
    }

    .callToActions {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);

      i {
        display: none;
      }

      ul,
      li {
        margin: 0;
        padding: 0;
      }

      li {
        display: inline-block;
        list-style-type: none;
        margin-right: 3rem;

        &:last-child {
          margin-right: 0;
        }

        a,
        span {
          font-size: 1.8rem;
          line-height: 1;
          color: black;
          text-decoration: none;
          text-transform: uppercase;
          cursor: pointer;
        }

        a:hover {
          color: rgb(255, 87, 87);
          -webkit-transition: all 0.4s ease-in-out;
          -moz-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
        }
      }
    }
  }
}

@media only screen and (max-width: 19rem) {
  .header .wrap {
    .callToActions {
      i {
        display: inline-block;
      }

      ul li {
        a,
        span {
          font-size: 0;

          i {
            font-size: 1.8rem;
          }
        }
      }
    }

    .mainMenu {
      display: none;
      position: absolute;
      height: auto;
      top: 100%;
      left: 0;
      z-index: 10;
      background-color: white;
      border-bottom: 1px solid lightgray;

      &.active {
        display: block;
      }

      ul li {
        width: 100%;
        margin: 0;
        border-top: 1px solid lightgray;

        a {
          display: block;
          width: 100%;
          text-align: left;
          padding: 0 10px;
        }
      }
    }
  }
}
