.products {
    display: block;
    padding: 0;
    width: 100%;
    padding-bottom: 2.5rem;

    .wrap-collabsible {
        input[type="checkbox"] {
            display: none;
        }

        .lbl-toggle {
            display: block;
            width: 35rem;
            font-size: 1.4rem;
            text-align: center;

            padding: 1rem;

            color: white;
            background: black;

            cursor: pointer;

            border-radius: 0.5rem;
            -webkit-transition: all 0.25s ease-in-out;
            -moz-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;
        }

        .lbl-toggle:hover {
            color: white;
            background: rgb(255, 87, 87);
        }

        .lbl-toggle::before {
            content: " ";
            display: inline-block;

            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid currentColor;

            vertical-align: middle;
            margin-right: 0.7rem;
            transform: translateY(-2px);

            -webkit-transition: all 0.4s ease-in-out;
            -moz-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
        }

        .collapsible-content {
            max-height: 0px;
            overflow: hidden;

            -webkit-transition: all 0.25s ease-in-out;
            -moz-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;
        }

        .toggle:checked + .lbl-toggle + .collapsible-content {
            max-height: 100vh;
        }

        .toggle:checked + .lbl-toggle::before {
            transform: rotate(90deg) translateX(-3px);
        }

        .toggle:checked + .lbl-toggle {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        a {
            background: white;
            padding: 8px 10px;
            border: 1px solid black;
            color: black;
            border-radius: 0.5rem;
        }

        a:hover {
            background: rgb(255, 87, 87);
            color: white;
            border: 1px solid white;
            -webkit-transition: all 0.4s ease-in-out;
            -moz-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
        }
    }

    ul,
    li {
        display: inline-block;
        margin-top: 1rem;
        padding: 0;
    }

    li {
        list-style-type: none;
        padding: 1rem;
        margin-bottom: 1rem;
        font-size: 1.4rem;
        font-weight: 400;
    }

    .productResults {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }
}

.product {
    width: 17%;
    margin: 0 auto 5rem;
    padding: 0 10px;
    border-style: solid;
    border-radius: 1rem;
    border-color: #d3d3d347;
    border-width: thin;

    .store {
        margin-top: 1rem;

        img {
            max-width: 7rem;
            max-height: 1.5rem;
        }

        .not-in-stock {
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            filter: grayscale(100%);
        }
    }

    .thumb {
        display: block;
        width: 100%;

        img {
            display: block;
            width: 75%;
            margin: 0 auto;
            margin-top: 1rem;
        }

        .not-in-stock {
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            filter: grayscale(100%);
        }
    }

    .out-of-stock {
        background-color: rgb(255, 87, 87);
        border-radius: 0.5rem;
        color: white;
        float: right;
        font-size: 1.1rem;
        font-weight: 700;
        padding: 0.3rem;
        text-transform: uppercase;
    }

    .in-stock {
        background-color: #5cb85c;
        border-radius: 0.5rem;
        color: white;
        float: right;
        font-size: 1rem;
        font-weight: 700;
        padding: 0.3rem;
        text-transform: uppercase;
    }

    .details {
        display: block;
        width: 100%;
        padding: 0 0;
        margin: 0 auto;
        margin-bottom: 1rem;
        margin-top: 1rem;

        ul,
        ul li {
            padding: 0;
            margin: 0;
        }

        ul {
            li {
                display: block;
                width: 100%;
                list-style: none;
                text-align: left;
                margin: 0 0 0.5rem;
                height: 6rem;
                position: relative;

                .name {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 1.6rem;
                    line-height: 1.2;
                    font-weight: 700;
                    color: black;
                    user-select: none;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    margin-top: 0.5rem;
                    span {
                        color: #009900;
                    }
                }

                .stock {
                    font-size: 1.4rem;
                    line-height: 1;
                    font-weight: 400;
                    color: black;
                }
            }
        }
        .price {
            font-size: 2rem;
            line-height: 1;
            font-weight: 700;
            color: rgb(255, 87, 87);
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
        }

        .description {
            margin-top: 3rem;
            font-size: 1.3rem;
            line-height: 1.5rem;
        }
    }
}

.product:hover {
    box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border-radius: 1rem;
}

@media only screen and (max-width: 90rem) {
    .product {
        width: 24%;
    }
}

@media only screen and (max-width: 70rem) {
    .product {
        width: 32%;
    }
}

@media only screen and (max-width: 45rem) {
    .product {
        width: 49%;
    }
}

@media only screen and (max-width: 35rem) {
    .product {
        width: 100%;
    }
}
