.title {
    min-height: calc(100vh - 8.5rem - 5.8rem - 2rem);
    display: block;
    text-align: center;
    font-size: 14rem;
    font-weight: 700;
    color: #ff5757;
    .subtitle {
        color: #ff5757;
        text-align: center;
    }
}
