.directory {
    height: 100%;

    .wrap {
        display: inline-block;
        width: 100%;
        height: 100%;

        .item {
            position: relative;
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.1);
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            ul {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                z-index: 3;
            }

            a {
                position: relative;
                font-size: 3rem;
                line-height: 3;
                font-weight: 400;
                text-transform: uppercase;
                background: rgb(255, 87, 87);
                color: white;
                border: 1px solid white;
                padding: 8px 10px;
                margin: 0 2rem 0 0;
                border-radius: 0.5rem;
            }
        }
    }
}
