.formRow {
  display: inline-block;
  width: 100%;

  label {
    display: block;
    width: 100%;
    text-align: left;
  }

  select {
    display: block;
    width: 35rem;
    height: 3.4rem;
    float: left;
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 700;
    text-align: center;
    padding: 0.5rem 0px;
    background-color: white;
    color: black;
    margin: 1.5rem auto;
    border-radius: 0.5rem;
    border-style: solid;
    outline: none;
    cursor: pointer;
    margin-top: 1.5rem;
  }
}
