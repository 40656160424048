.footer {
    .wrap {
        max-width: 100%;
        margin: 0 auto;
        padding: 2rem 15rem;
        background-color: rgb(255, 87, 87);
        color: white;
        font-weight: 700;
    }
}
