.btn {
    display: block;
    width: 20%;
    background: black;
    color: white;
    font-size: 1.8rem;
    line-height: 1;
    font-weight: 300;
    text-transform: uppercase;
    padding: 1rem 0;
    margin: 0 auto;
    border: 0;
    outline: none;
    cursor: pointer;
    border-radius: 0.5rem;
}

.btn:hover {
    background-color: rgb(255, 87, 87);
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
